import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex h-screen bg-gray-100 dark:bg-gray-800 font-roboto" }
const _hoisted_2 = { class: "flex-1 flex flex-col overflow-hidden" }
const _hoisted_3 = { class: "flex-1 overflow-x-auto overflow-y-auto pb-24" }
const _hoisted_4 = { class: "container mx-auto md:px-6 xs:py-4 md:py-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sidebar = _resolveComponent("sidebar")!
  const _component_header_bar = _resolveComponent("header-bar")!
  const _component_top_button = _resolveComponent("top-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_sidebar),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_header_bar, {
        onOpenSidebar: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isSidebarOpen = true))
      }),
      _createElementVNode("main", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _renderSlot(_ctx.$slots, "default")
        ])
      ]),
      _createVNode(_component_top_button, { "window-selector": "main" })
    ])
  ]))
}