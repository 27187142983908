
import {
  defineComponent,
  ref,
  Ref,
  onMounted,
  onBeforeUnmount,
} from 'vue';

export default defineComponent({
  name: 'topButton',
  props: {
    windowSelector: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const isButtonVisible:Ref<boolean> = ref(false);

    onMounted(() => {
      const element = document.querySelector(props.windowSelector);
      if (!element) {
        throw new Error(`Element ${props.windowSelector} not found !`);
      }
      const scrollHandler = () => {
        isButtonVisible.value = element.scrollTop > 300;
      };
      element.addEventListener('scroll', scrollHandler);

      onBeforeUnmount(() => {
        element.removeEventListener('scroll', scrollHandler);
      });
    });

    const scrollToTop = () => {
      const element = document.querySelector(props.windowSelector);
      if (!element) {
        throw new Error(`Element ${props.windowSelector} not found !`);
      }
      element.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    };

    return {
      scrollToTop,
      isButtonVisible,
    };
  },
});
