
import { defineComponent, computed } from 'vue';
import { useRoute } from 'vue-router';

import { useStore } from '@/store/index';
import { ApplicationMutationTypes } from '@/store/types/mutation.type';

export default defineComponent({
  name: 'loggedSidebar',
  setup() {
    const store = useStore();
    const route = useRoute();

    const isSidebarOpen = computed({
      get() {
        return store.getters['applicationStore/isSidebarOpen'];
      },
      set(value: boolean) {
        store.commit(ApplicationMutationTypes.SET_IS_SIDEBAR_OPEN, value);
      },
    });

    const isActive = (routeName: string[]):boolean => routeName.includes(route.name as string);

    return {
      isSidebarOpen,
      isActive,
    };
  },
});
