
import {
  defineComponent,
  computed,
} from 'vue';

import { useStore } from '@/store/index';
import { ApplicationMutationTypes } from '@/store/types/mutation.type';

import Sidebar from '@/components/layout/logged/Sidebar.vue';
import HeaderBar from '@/components/layout/logged/HeaderBar.vue';
import TopButton from '@/components/layout/logged/TopButton.vue';

export default defineComponent({
  name: 'logged',
  components: {
    Sidebar,
    HeaderBar,
    TopButton,
  },
  setup() {
    const store = useStore();

    const isSidebarOpen = computed({
      get(): boolean {
        return store.getters['applicationStore/isSidebarOpen'];
      },
      set(value: boolean) {
        store.commit(ApplicationMutationTypes.SET_IS_SIDEBAR_OPEN, value);
      },
    });

    return {
      isSidebarOpen,
    };
  },
});
