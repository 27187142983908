
import Cookies from 'js-cookie';
import {
  defineComponent,
  computed,
  ComputedRef,
  ref,
  onBeforeUnmount,
} from 'vue';

import { useRouter, useRoute } from 'vue-router';

import { useStore } from '@/store/index';
import { ApplicationMutationTypes, AuthMutationTypes } from '@/store/types/mutation.type';

export default defineComponent({
  name: 'loggedHeaderBar',
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    const isSidebarOpen = computed({
      get(): boolean {
        return store.getters['applicationStore/isSidebarOpen'];
      },
      set(value: boolean) {
        store.commit(ApplicationMutationTypes.SET_IS_SIDEBAR_OPEN, value);
      },
    });

    const isOffline = ref(false);

    const isDarkMode = computed({
      get(): boolean {
        return store.getters['applicationStore/isDarkMode'];
      },
      set(value: boolean) {
        store.commit(ApplicationMutationTypes.SET_IS_DARK_MODE, value);
      },
    });

    const name = computed(() => store.getters['userStore/user']?.username);

    const mangaName = computed(() => store.getters['mangaStore/manga']?.name);

    const chapterName = computed(() => store.getters['mangaStore/chapter']?.name);

    const usermenu = ref();

    const isDropdownOpen = ref(false);

    const openDropdown = () => {
      isDropdownOpen.value = true;
      const eventClick = (event: Event) => {
        if (!usermenu.value.contains(event.target)) {
          isDropdownOpen.value = false;
        }
        document.removeEventListener('click', eventClick);
      };
      document.addEventListener('click', eventClick);
    };

    const logout = () => {
      store.commit(AuthMutationTypes.SET_IS_LOGGED, false);
      Cookies.remove(process.env.VUE_APP_COOKIE_TOKEN_NAME);
      router.push({ name: 'login' });
    };

    type Redirection = {
      name: string,
      params: {
        [key:string]: string,
      },
    };

    const mangaRedirection: ComputedRef<Redirection | null> = computed(() => {
      if (route.params.chapterNumber) {
        return { name: 'manga', params: { mangaName: route.params.mangaName as string } };
      }
      return null;
    });

    const chapterRedirection: ComputedRef<Redirection | null> = computed(() => {
      if (route.params.pageNumber) {
        return {
          name: 'chapter',
          params: {
            chapterNumber: route.params.chapterNumber as string,
            mangaName: route.params.mangaName as string,
          },
        };
      }
      return null;
    });

    const updateOnlineStatus = () => {
      isOffline.value = !navigator.onLine;
    };

    window.addEventListener('online', updateOnlineStatus);
    window.addEventListener('offline', updateOnlineStatus);

    onBeforeUnmount(() => {
      window.removeEventListener('online', updateOnlineStatus);
      window.removeEventListener('offline', updateOnlineStatus);
    });

    return {
      isOffline,
      isSidebarOpen,
      mangaRedirection,
      chapterRedirection,
      mangaName,
      chapterName,
      isDarkMode,
      name,
      usermenu,
      isDropdownOpen,
      openDropdown,
      logout,
    };
  },
});
